
import OsanoFooterLink from '@root/joinroot.com/src/components/footer/osano-footer-link';
import PageTemplate from '@root/joinroot.com/src/components/pages/page-template';
import PrivacyActRequestForm from '@root/joinroot.com/src/components/privacy/privacy-act-request-form';
import PrivacyActRequestFormConfirmation from '@root/joinroot.com/src/components/privacy/privacy-act-request-form-confirmation';
import PrivacyActRequestFormRetry from '@root/joinroot.com/src/components/privacy/privacy-act-request-form-retry';
import PrivacyActRequestFormSuccess from '@root/joinroot.com/src/components/privacy/privacy-act-request-form-success';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import ViewPrivacyPolicyCta from '@root/joinroot.com/src/components/privacy/view-privacy-policy-cta';
import environment from '@root/core/src/utils/environment';
import submitPrivacyActRequestForm from '@root/joinroot.com/src/api/submit-privacy-act-request-form';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { PageContent } from '@root/gatsby-contentful-core/src/components/layout/content';
import { useState } from '@root/vendor/react';

const FormStates = {
  CONFIRMING: 'confirming',
  FAILED: 'failed',
  FILLING: 'filling',
  SUBMITTING: 'submitting',
  SUCCESS: 'success',
};

export default function CaliforniaPrivacyPage({ location }) {
  const [formValues, setFormValues] = useState({});
  const [formState, setFormState] = useState(FormStates.FILLING);
  const { trackClick } = useAnalytics('CALIFORNIA_CONSUMER_PRIVACY_ACT_PAGE');
  const [submitFormData] = useSafeImperativeNetworkRequest(submitPrivacyActRequestForm);

  const formSubmit = (values) => {
    trackClick('PRIVACY_ACT_REQUEST_FORM_SUBMITTED');
    setFormValues(values);
    setFormState(FormStates.CONFIRMING);
  };

  // eslint-disable-next-line root/prevent-use-of-window-location
  const formCancelPress = () => window.location.reload();

  const formConfirmationPress = () => {
    submitFormData(formValues).then((response) => {
      setFormState(response.isSuccess() ? FormStates.SUCCESS : FormStates.FAILED);
    });

    setFormState(FormStates.SUBMITTING);
  };

  // eslint-disable-next-line root/prevent-use-of-window-location
  const formDonePress = () => window.location.reload();

  const formRetryPress = () => {
    submitFormData(formValues).then((response) => {
      setFormState(response.isSuccess() ? FormStates.SUCCESS : FormStates.FAILED);
    });

    setFormState(FormStates.SUBMITTING);
  };

  const renderModal = () => {
    switch (formState) {
    case FormStates.CONFIRMING:
    case FormStates.SUBMITTING:
      return (
        <PrivacyActRequestFormConfirmation
          deleteData={formValues.deleteData}
          onCancelPress={formCancelPress}
          onConfirmationPress={formConfirmationPress}
          submitting={formState === FormStates.SUBMITTING}
        />
      );
    case FormStates.FAILED:
      return (
        <PrivacyActRequestFormRetry
          onCancelPress={formCancelPress}
          onRetryPress={formRetryPress}
        />
      );
    case FormStates.SUCCESS:
      return (
        <PrivacyActRequestFormSuccess
          onCancelPress={formCancelPress}
          onDonePress={formDonePress}
        />
      );
    case FormStates.FILLING:
    default:
      return null;
    }
  };

  return (
    <PageTemplate
      deIndexPage
      location={location}
      pageCanonical={'https://www.joinroot.com/privacy/california/'}
      pageTitle={'Data Collection at Root'}
    >
      <header css={styles.header}>
        <div css={styles.contentWrapper}>
          <h1 css={styles.h1}>Data Collection at Root</h1>
        </div>
      </header>
      <ViewPrivacyPolicyCta />
      <PageContent>
        <div css={styles.manageCookiesLink}>
          {environment.osanoEnabled === 'true' && <OsanoFooterLink />}
        </div>
        <h2 css={styles.subheader}>Opt-out of data sharing, have it be deleted, or both</h2>
        <PrivacyActRequestForm onFormSubmit={formSubmit} />
        {renderModal()}
      </PageContent>
    </PageTemplate>
  );
}

CaliforniaPrivacyPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }.isRequired),
};

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    alignItems: 'center',
    background: Colors.black(),
    color: Colors.white(),
    padding: '60px 20px 110px',
    textAlign: 'center',
    ...Responsive.md({
      padding: '80px 60px 130px',
    }),
    ...Responsive.lg({
      padding: '110px 120px 160px',
    }),
  },
  contentWrapper: {
    maxWidth: 335,
    margin: 'auto',
    ...Responsive.sm({
      maxWidth: 500,
    }),
    ...Responsive.md({
      maxWidth: 700,
    }),
    ...Responsive.lg({
      maxWidth: 1200,
    }),
  },
  h1: {
    ...Theme.heading2(),
    marginBottom: 15,
    color: Colors.white(),
  },
  subcopy: {
    ...Theme.subhead2Light(),
    margin: '25px auto',
    color: Colors.white(),
    ...Responsive.lg({
      width: 900,
      margin: '30px auto',
    }),
  },
  subheader: {
    ...Theme.subhead1Light(),
    fontSize: 36,
    textAlign: 'center',
    margin: 30,
  },
  manageCookiesLink: {
    textAlign: 'center',
    margin: 0,
  },
});
